<template>
  <div class="contentOne">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="支付宝账号管理" name="1"></el-tab-pane>
      <el-tab-pane label="银行账号管理" name="2"></el-tab-pane>
      <!-- <alipay-account v-if="activeName === '1'"></alipay-account> -->
      <!-- <bank-account v-else></bank-account> -->
      <!-- 支付宝账号管理 -->
      <div v-if="activeName == '1'" style="width:50%;">
        <el-form :rules="rules"  ref="rulesForm" :model="formData" label-width="100px">
          <el-form-item label="账号" prop="alipayAccount">
            <el-input placeholder="请输入账号" v-model="formData.alipayAccount"></el-input>
          </el-form-item>
          <el-form-item label="真实姓名" prop="alipayName">
            <el-input placeholder="请输入真实姓名" v-model="formData.alipayName"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="alipayPhone">
            <el-input placeholder="请输入手机号" v-model="formData.alipayPhone"></el-input>
          </el-form-item>
          <!-- <el-form-item label="备注">
            <el-input type="textarea"></el-input>
          </el-form-item> -->
        </el-form>
        <div style="text-align: center;">
          <el-button v-loading="loadingStatus.getAlipaySubmit" type="primary" @click="getAlipaySubmit">保 存</el-button>
        </div>
      </div>
      <!-- 银行卡 -->
      <div v-if="activeName == '2'" style="width: 60%;">
        <el-form :rules="rulesBank" ref="rulesBankForm" :model="formBankData" label-width="125px">
          <el-row>
            <el-col :xs="24" :md="24" :lg="12">
              <el-form-item label="银行卡类型：" prop="idType">
                <el-select style="width: 100%" v-model="formBankData.idType">
                  <el-option v-for="item in idTypeList" :key="item.id" :value="item.name" :label="item.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
              <el-col :xs="24" :md="24" :lg="12">
                <el-form-item label="开户银行：" prop="bankName">
                  <el-input placeholder="请输入开户银行" v-model="formBankData.bankName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :md="24" :lg="12">
                <el-form-item label="开户银行支行：" prop="bankBranchName">
                  <el-input placeholder="请输入开户银行支行" v-model="formBankData.bankBranchName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :md="24" :lg="12">
                <el-form-item label="支行所在地：" prop="bankAccountAddress">
                  <el-input placeholder="请输入支行所在地" v-model="formBankData.bankAccountAddress"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :md="24" :lg="12">
                <el-form-item label="账号名：" prop="bankAccountName">
                  <el-input placeholder="请输入账号名" v-model="formBankData.bankAccountName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :md="24" :lg="12">
                <el-form-item label="银行卡号：" prop="bankAccountNo">
                  <el-input placeholder="请输入银行卡号" v-model="formBankData.bankAccountNo"></el-input>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :md="24" :lg="12">
                <el-form-item label="真实姓名：" prop="payeeName">
                  <el-input placeholder="请输入真实姓名" v-model="formBankData.payeeName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :md="24" :lg="12">
                <el-form-item label="手机号：" prop="payeePhone">
                  <el-input placeholder="请输入手机号" v-model="formBankData.payeePhone"></el-input>
                </el-form-item>
              </el-col>
            <el-col :xs="24" :md="24" :lg="12">
                <el-form-item label="币种：">
                  <el-input id="currency" disabled="disabled" value="人民币"></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="24">
                <el-form-item label="备注">
                  <el-input type="textarea"></el-input>
                </el-form-item>
              </el-col> -->
          </el-row>
        </el-form>
        <div style="text-align: center;">
<!--          <div>11:{{ loadingStatus.getBankSubmit }}</div>-->
          <el-button :loading="loadingStatus.getBankSubmit" type="primary" @click="getBankSubmit">保 存</el-button>
        </div>
      </div>
    </el-tabs>
  </div>
</template>

<script>
import loadingStatusMixin from "@/mixins/loadingStatus";
import {
  accountAdminAlipayAccountAdmin,
  alipayAccountAdminInfo,
  bankAccountAdminInfo,
  accountAdminBankAccountAdmin
} from "@/service/moudules/promotionLinkApi";
import moment from "moment";
// import alipayAccount from "./components/alipayAccount"; //支付宝账号管理
// import bankAccount from "./components/bankAccount"; //银行账号
export default {
  name: "withdrawAccount",
  mixins: [loadingStatusMixin],
  // components: {
  //   alipayAccount,
  //   bankAccount
  // },
  data() {
    return {
      alipayData: null,
      bankDate: null,
      activeName: '1',
      formData: {
        alipayAccount: '', //支付宝账号
        alipayName: '', //姓名
        alipayPhone:'', //手机码
      },
      rules: {
        alipayAccount: [
          { required: true, message: '请输入支付宝账号', trigger: 'blur' },
        ],
        alipayName: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
          {
            validator: function(rule, value, callback) {
              if (/^[\u4e00-\u9fa5]+$/.test(value) == false) {
                callback(new Error("请输入中文姓名"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur"
          }
        ],
        alipayPhone: [
          { required: true, validator: (rule,value,callBack)=> {
              const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
              if (!reg.test(value)) {
                callBack(new Error('请输入正确手机号码'));
              } else {
                callBack();
              }
            }, trigger: 'blur' },
        ],
      },
      formBankData: {
        idType: '', //0 -> 自然， 1 -> 个人 , 2 ->组积
        // bankKey: '', //银行关联编码
        bankName: '', //银行名称
        bankAccountNo: '', //银行卡号
        bankAccountName: '', //银行帐号名
        currency: 'RMB', //币种
        // subjectCode: '', //科目代码
        // code: '', //自定义科目编码
        bankAccountAddress: '', //开户行所在地
        bankBranchName: '', //开户支行名称
        // toPrivate: '', //对公或对私
        payeeName: '', //收款人名称
        payeePhone: '', //收款人手机号码
      },
      rulesBank: {
        idType: [
          { required: true, message: '请选择银行卡类型', trigger: 'blur' },
        ],
        bankName: [
          { required: true, message: '请输入开户银行', trigger: 'blur' },
        ],
        bankBranchName: [
          { required: true, message: '请输入开户银行支行', trigger: 'blur' },
        ],
        bankAccountAddress: [
          { required: true, message: '请输入支行所在地', trigger: 'blur' },
        ],
        bankAccountName: [
          { required: true, message: '请输入账号名', trigger: 'blur' },
        ],
        bankAccountNo: [
          { required: true, validator: (rule,value,callBack)=> {
              let reg = /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/
              if (!reg.test(value)) {
                callBack(new Error('请输入银行卡号'));
              } else {
                callBack();
              }
            }, trigger: 'blur' },
        ],
        payeeName: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
          {
            validator: function(rule, value, callback) {
              if (/^[\u4e00-\u9fa5]+$/.test(value) == false) {
                callback(new Error("请输入中文姓名"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur"
          }
        ],
        payeePhone: [
          { required: true, validator: (rule,value,callBack)=> {
              const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
              if (!reg.test(value)) {
                callBack(new Error('请输入正确手机号码'));
              } else {
                callBack();
              }
            }, trigger: 'blur' },
        ],
      },
      idTypeList: [
        {id:0, name: '自然'},
        {id:1, name: '个人'},
        {id:2, name: '组织'},
      ],
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    handleClick(tab,event) {
      console.log("val:",tab,event,this.activeName)
      // this.activeName =val;
      // if(this.activeName == '1') {
      //   this.$refs.rulesForm.resetFields();
      // } else {
      //   this.$refs.rulesBankForm.resetFields();
      // }
    },
    async getDetail() {
      let res = await alipayAccountAdminInfo();
      if(res.code == 200) {
        this.alipayData = res.data;
        if(res.data != null) {
          this.formData = res.data;
        }
      }
      let res2 = await bankAccountAdminInfo();
      if( res2.code == 200 ) {
        this.bankDate = res2.data;
        console.log(3333)
        if(res2.data != null) {
          this.formBankData = {...res2.data};
          this.formBankData.idType = this.formBankData.idType == '0' ? '自然' : this.formBankData.idType == '1' ? '个人' :  '组织'
          console.log("333:",this.formBankData)
        }
      }
      console.log("res:",res,res2);
    },
    // 支付宝-确认
    getAlipaySubmit() {
      this.$refs.rulesForm.validate(async (valid) => {
        if ( this.hasLoadingState('getAlipaySubmit') ) return;
        if (valid) {
          try {
            let params = {
              ...this.formData
            }
            let res = await accountAdminAlipayAccountAdmin(params);
            this.removeLoadingState("getAlipaySubmit");
            if (res.code === 200) {
              this.getDetail();
              this.$message.success(`添加支付宝账号成功！`);
            } else {
              this.$message.error(`${res.msg}！`);
            }
          }catch (e) {
            this.removeLoadingState("getAlipaySubmit");
          }

        } else {
          console.log('error submit!!');
          this.removeLoadingState("getAlipaySubmit");
          return false;
        }
      });
    },
    // 银行卡
    getBankSubmit() {
      this.$refs.rulesBankForm.validate(async (valid) => {
        if ( this.hasLoadingState('getBankSubmit') ) return;
        if (valid) {
          try {
            let params = {
              ...this.formBankData
            }
            params.idType = params.idType == '自然' ? '0': params.idType == '个人' ? '1' : '2';
            let res = await accountAdminBankAccountAdmin(params);
            this.removeLoadingState("getBankSubmit");
            if (res.code === 200) {
              this.getDetail();
              this.$message.success(`添加银行卡账号成功！`);
            } else {
              this.$message.error(`${res.msg}！`);
            }
          }catch (e) {
            this.removeLoadingState("getBankSubmit");
          }

        } else {
          console.log('error submit!!');
          this.removeLoadingState("getBankSubmit");
          return false;
        }
      });
    },
  }
}
</script>

<style lang="less" scoped>
.contentOne {
  padding: 20px;
  margin: 10px;
  border: 1px solid #dcdfe6;
  text-align: initial;
  background-color: white;
  
}
#currency {
  ::v-deep .el-input.is-disabled .el-input__inner{
    color: #000 !important;
}
  }
  
</style>